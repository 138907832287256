import Layout from '../../modules/layout/layout';
import { Box, Text, HStack, Image, useToast, Button, VStack } from '@chakra-ui/react';
import { useMediaQuery } from '../../utils/useMediaQuery';
import AppStore from '../../static/assets/images/app-store.svg';
import GooglePlay from '../../static/assets/images/google-play.svg';
import { theme } from '../../styles/theme/base';
import {QRAppComponentDesktop} from './downloadApp';
import exportedBackground from '../../static/assets/images/exported-background.jpg';
import secrets from '../../secrets';
export default function DownloadApp(props) {
    const toast = useToast();
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Layout {...props} bgColor="#000" alignItems="center" justifyContent="center">
        {!isTablet ? 
      <Box 
        display="flex" 
        alignItems="center"
        justifyContent="center"
        padding="2rem 5.88rem 3.94rem 5.81rem"
        maxWidth="1220px"
        width="100%"
        margin="0 auto"
      >
        <Box
          display="flex"
          width="33%"
          flexDirection="column"
          alignItems="flex-start"
          gap="2rem"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
            alignSelf="stretch"
          >
            <Text
              color="#FFF"
              fontFamily="Gordita"
              fontSize="3rem"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="132%"
              letterSpacing="-0.06rem"
            >
              Get the app!
            </Text>

            <Text
              color="rgba(255, 255, 255, 0.64)"
              fontFamily="Gordita"
              fontSize="1.25rem"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="148%"
            >
              The simplest and secure way to get your tickets and access the events.
            </Text>
          </Box>
        </Box>

            <QRAppComponentDesktop  width="66%" />
      </Box>
      : (
      <Box width="100%" padding="2rem" flexDirection="column"
      justifyContent="center"
      alignItems="center"
      background={`url(${exportedBackground}) no-repeat center center`}
      backgroundSize="cover"
      backgroundPosition="center"
      height="100vh"
      position="relative"
      overflow="visible"  >
        <VStack marginTop="1rem" gap="2rem" width="100%">
          <Text
            color="#FFF"
            textAlign="center"
            fontFamily="Gordita"
            fontSize="2rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="132%"
            letterSpacing="-0.04rem"
            alignSelf="stretch"
          >
            Your next plan is now on the app!
          </Text>
          
          <Text
            alignSelf="stretch"
            color="rgba(255, 255, 255, 0.64)"
            textAlign="center"
            fontFamily="Gordita"
            fontSize="1rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="148%"
          >
            The simplest and secure way to get your tickets and access the events.
          </Text>

          <Button
        fontWeight="700"
        textAlign="center"
        margin={isMobile ? "0 20px" : "20px 0"}
        fontSize="1rem"
        borderRadius="40px"
        outline="none"
        sx={theme.buttons.primary.shape}
        bg={theme.buttons.primary.default}
        color={theme.buttons.primary.color}
        className="gordita700"
        css={{
          width: "100%",
        }}
        _hover={{
          bg: theme.buttons.primary.hover,
          boxShadow:

            theme.buttons.primary.hoverBoxShadow,
          cursor: "pointer",
        }}
        _active={{
          boxShadow:
           
            theme.buttons.primary.activeBoxShadow,
        }}
        _disabled={{
          bg: theme.buttons.primary.disabled,
          color: theme.buttons.primary.colorDisabled,
        }}
        
        
        onClick={() =>
          window.open(secrets.portalysDownloadLink, '_blank')
          }
      >
        Download Portalys App
      </Button>
        </VStack>
      </Box>
      )
    }
    </Layout>
  );
}
