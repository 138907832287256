/**
 * The HomePage function returns the JSX elements for the home page of an event ticketing website,
 * including search, events, maps, tickets, FAQs, and a call to action for becoming an organizer.
 * @returns This is a React functional component that returns JSX elements to render a home page for an
 * event ticketing website. It includes various sections such as a search section, events section, map
 * section, tickets section, FAQs section, and a section for becoming an organizer. It also uses
 * various Chakra UI components and custom hooks to handle media queries and state management with
 * Recoil.
 */
import {
  useColorMode,
  Stack,
  Box,
  Text,
  Flex,
  VStack,
  Image,
  HStack,
  useToast,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { HomeWrapper } from "../../../styles/pages/home";
// import Button from "../../shared/button";
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { filters } from "../../../recoil/atoms/filters";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import Map from "../../../utils/Map";
import { cities } from "../../../utils/constants";
import axios from "../../../utils/api/axios";
import secrets from "../../../secrets";
import NewsEvents from "../../../pages/portalys/newsevents";
import EventsOverview from "./EventsOverview/index";
import { theme } from "../../../styles/theme/base";
import { userRoles } from "../../../utils/constants";
import { useScrollYPosition } from "react-use-scroll-position";
import styles from "./style.css";
import FAQsComponent from "../../../pages/portalys/faqs";
import ImageTextComponent from "./EventsOverview/ImageTextComponent";
import marketplace2 from "../../../static/assets/images/marketplace2.png";
import marketplace2Mobile from "../../../static/assets/images/marketplace2Mobile.png";
import marketplace3 from "../../../static/assets/images/marketplace3.png";
import { BackTop } from "antd";
import { AiOutlineArrowUp } from "react-icons/ai";
import { FloatButton } from "antd";
import arrowUpIcon from "../../../static/assets/images/arrowUpIcon.svg";
import bigArrowUp from "../../../static/assets/images/bigArrowUp.svg";
import { NotificationComponent } from "../../shared/notificationComponent";

const faqsList = [
  {
    key: "1",
    label: "How to access my event after buying a ticket?",
    children:
      "Log In using your account’s phone number to see your Event Wallet QR code and show it to the event organizer. Or request it on your confirmation email to receive a link by text message. By SMS, the QR code changes every 30 seconds for screenshot protection; no screenshots work 😕 . No connection? No problem, say your name and present an ID at the door, and you’re all set.",
  },
  {
    key: "2",
    label: "What happens if I buy tickets for my friends?",
    children: `All your tickets will be inside your QR code. If you need to share a ticket, you can do so easily on the "My Tickets" page. Simply enter the event, select the ticket you want to share, and choose the recipient.`,
  },
  {
    key: "3",
    label: "Can someone steal/replicate my tickets?",
    children:
      "Your tickets cannot be stolen, duplicated, or hacked. We use blockchain for the good, making it easy for everyone. Get your tickets with peace of mind. Portalys is 100% free of fraud.",
  },
  {
    key: "4",
    label: "What if I don’t see my ticket(s)?",
    children:
      "Most commonly, failed payments. Your bank did not process a payment to us.However, if you don’t see your tickets on your “My Account” page, please email hello@portalys.io with your first and last name and phone number.",
  },
  {
    key: "5",
    label: "How can I get assistance when needed?",
    children:
      "Send us a DM on our social accounts; Instagram, Twitter, and LinkedIn. Or send us an email at hello@portalys.io.",
  },
];

const HomePage = () => {
  AOS.init();
  const { colorMode } = useColorMode();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isMobileXS = useMediaQuery("(max-width: 568px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const isbreaking = useMediaQuery("(max-width: 1265px)");
  const isSmallMac = useMediaQuery("(max-width: 1400px)");
  const toast = useToast();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [longitude, setLongitude] = useState(cities[0].longitude);
  const [latitude, setLatitude] = useState(cities[0].latitude);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSearchBarVisible(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const maxW = "1662px";
  const navigate = useNavigate();
  const [_, setFilters] = useRecoilState(filters);
  const [_U, setUser] = useRecoilState(user);
  const userRole = userRoles.ATTENDEE;
  const getFilteredEvents = async () => {
    setIsLoading(true);
    await axios
      .post("/api/filteredEvents", {
        city: _.filters?.city || "",
        type: _.filters?.category || "",
        name: _.filters?.name || "",
        userId:_U?.userData?.userId ||"",
      })
      .then((res) => {
        const data = res.data;
        setEvents(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log({ err });
      });
  };


  const init = async () => {
    try {
      let val = getFilteredEvents();
      if (!isEmpty(val)) {
        const newEvents = [];
        Object.values(val).forEach((et) => {
          newEvents.push({ ...et });
        });
        setEvents(newEvents);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
  }, [_U?.userData?.userId]);
  useEffect(() => {
    if (scrollY > 0) {
      setIsSearchBarVisible(true);
    } else {
      setIsSearchBarVisible(false);
    }
  }, [scrollY]);
  const changeLocationCity = (city) => {
    const selectedCity = cities.find((c) => c.label === city);
    setLatitude(selectedCity.latitude);
    setLongitude(selectedCity.longitude);
  };



  const ImageCardComponent = () => {
    return (
      <Box width={"100%"}>
        <Image
          src={isMobileXS ? marketplace2Mobile : marketplace2}
          height={isMobile ? "36.8125rem" : "48rem"}
          objectFit={"cover"}
          width={"100%"}
          flexShrink={0}
        />
        <VStack
          gap={"0.3125rem"}
          margin={"-8.5rem auto"}
          width={isTablet ? "95%" : "48.75rem"}
          padding={isMobile ? "2.1875rem 1.25rem" : "2.8125rem 3.4375rem"}
          borderRadius={isMobile ? "1.5625rem" : "3.125rem"}
          boxShadow={
            "-1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
          }
          bg="rgba(0, 0, 0, 0.50)"
          backdropFilter={"blur(75px)"}
          marginBottom={"0"}
        >
          <Text
            textAlign={"center"}
            fontSize={isMobile ? "1.75rem" : "2.375rem"}
            className="gordita600"
            letterSpacing={"-0.02375rem"}
            textTransform={"capitalize"}
          >
            Need help?
            <br />
            Our support is here for you 24/7.
          </Text>
          <Button
            onClick={() => navigate("/contact-us")}
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            className="gordita700"
            fontSize="1rem"
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            style={{
              width: "fit-content",
              padding: "0 1.56rem",
              textTransform: "capitalize",
            }}
          >
            contact us
          </Button>
        </VStack>
      </Box>
    );
  };

  return (
    <HomeWrapper>
      <Stack
        margin={isMobile ? "5.00rem auto" : "5.00rem auto 3rem"}
        alignItems="center"
        justifyContent="center"
        w="100%"
        spacing={0}
        gap={isMobile ? "8.12rem" : "18.75rem"}
      >
        {/* <SearchSection
          {...{ isMobile, xsmall, isTablet, maxW, isLoading, setIsLoading }}
        /> */}
        <VStack
          maxW="76.13rem"
          padding={"0 1.19rem"}
          alignItems="center"
          justifyContent="center"
          w="100%"
          spacing={"0 !important"}
          margin={"0 !important"}
          gap={isMobile ? "8.12rem" : "18.75rem"}
        >
          <NewsEvents init={init} events={events} />
          <EventsOverview />
          {/* <FAQsComponent faqsList={faqsList} /> */}
        </VStack>

        {/* <ImageCardComponent />  */}
        

      </Stack>
    </HomeWrapper>
  );
};

export default HomePage;
