import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Button,
  Box,
} from '@chakra-ui/react';
import { theme } from "../../../styles/theme/base";


export const UnlockModal = ({
  isOpen,
  onClose,
  enteredTicketPassword,
  setEnteredTicketPassword,
  handleTicketPasswordSubmit,
  isMobile,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent color="white.100"
  borderRadius="2.5rem"
  boxShadow={
    isMobile
      ? "none"
      : "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
  }
  bg={"rgba(0, 0, 0, 0.50)"}
  backdropFilter={"blur(150px)"}>
      <ModalHeader  p={"40px 40px 0 40px"}>Unlock Hidden Items</ModalHeader>
      <ModalCloseButton />
      <ModalBody p={"40px 40px 40px 40px"} textAlign={"start"}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Input
            padding="1.25rem"
            borderRadius="0.625rem"
            border="1px solid rgba(255, 255, 255, 0.20)"
            backgroundColor="transparent"
            width="100%"
            height="3.4375rem"
            placeholder="Enter code"
            value={enteredTicketPassword}
            onChange={(e) => setEnteredTicketPassword(e.target.value)}
          />
          <Button
                fontWeight="700"
                textAlign="center"
                margin={"20px 0"}
                fontSize="1rem"
                borderRadius="40px"
                outline="none"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                className="gordita700"
                css={{
                  width: "100%",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow:
                    
                    theme.buttons.primary.hoverBoxShadow,
                 
                }}
                _active={{
                  boxShadow:
                    
                    theme.buttons.primary.activeBoxShadow,
                }}
                _disabled={{
                  bg: theme.buttons.primary.disabled,
                  color: theme.buttons.primary.colorDisabled,
                }}
            onClick={handleTicketPasswordSubmit}
          >
            Unlock
          </Button>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);
