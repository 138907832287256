import {
  Box,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  Stack,
  useToast,
  Button,
  Input,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import _isEmpty from 'lodash/isEmpty';
import Layout from '../../modules/layout/layout';
import cartoon from '../../static/assets/images/cartoon.svg';
import heartfull from '../../static/assets/images/HeartChecked.svg';
import heartempty from '../../static/assets/images/HeartUnchecked.svg';
import EmailIcon from '../../static/assets/images/emailIcon.svg';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { Icon } from '@chakra-ui/react';
import '../../styles/portalys/portalys.css';
import { user } from '../../recoil/atoms/user';
import { theme } from '../../styles/theme/base';
import axios from '../../utils/api/axios';
import secrets from '../../secrets';
import { NotificationComponent } from '../../modules/shared/notificationComponent';
import { useMediaQuery } from '../../utils/useMediaQuery';
const moment = require('moment-timezone');
import AppStore from '../../static/assets/images/app-store.svg';
import GooglePlay from '../../static/assets/images/google-play.svg';
import ImageTextComponent from '../../modules/pages/Home/EventsOverview/ImageTextComponent';
import appMockup from '../../static/assets/images/app-mockup.png';
import { MarketPlaceComponent } from '../appPromo/downloadApp';

export const formatDate = (dateString, timeZone) => {
  // Parse the input date string in the specified time zone
  const originalDate = moment.tz(dateString, timeZone);
  const day = originalDate.format('D');
  const month = originalDate.format('MMM');
  const weekday = originalDate.format('ddd');

  return `${weekday}, ${day} ${month}`;
};

// Function to format the time to 13h25 format
export const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  // Use Moment.js to format the time with AM/PM
  const formattedTime = moment()
    .hours(hours)
    .minutes(minutes)
    .format('hh:mm A');
  return formattedTime;
};

export default function NewsEvents({ init, events }) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 1179px)');
  const [email, setEmail] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  //const [events, setEvents] = useState([]);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();

  const handleAppStoreClick = () => {
    window.open(secrets.iosLink, '_blank');
  };

  const handleGooglePlayClick = () => {
    window.open(secrets.androidLink, '_blank');
  };
  const handlePortalysDownloadClick = () => {
    window.open(secrets.portalysDownloadLink, '_blank');
  };

  const handleLikeEvent = async (id) => {
    if (_.token) {
      await axios
        .post('/api/likeEvent/' + id, {
          headers: {
            'x-auth-token': _?.token,
          },
        })
        .then((res) => {
          init();
          const data = res.data;
          toast({
            position: 'top-right',
            duration: 4000,
            render: ({ onClose }) => (
              <NotificationComponent
                title={
                  data?.message === 'Event Liked'
                    ? 'Event has been added to your wishlist'
                    : 'Event has been removed from your wishlist'
                }
                status={data.status ? 'success' : 'error'}
                onClose={onClose}
              />
            ),
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      toast({
        position: 'top-right',
        duration: 4000,
        render: ({ onClose }) => (
          <NotificationComponent
            title={'Please Login'}
            status={'error'}
            onClose={onClose}
          />
        ),
      });
    }
  };
  const subscribe = async () => {
    console.log({ email });
    if (!email) {
      return toast({
        title: 'Please enter you email first!',
        status: 'warning',
        isClosable: true,
        duration: 4000,
        position: 'top-right',
      });
    }
    await axios
      .post('/api/subscribe', { email })
      .then((res) => {
        const data = res.data;
        toast({
          title: data.message,
          status: data.status ? 'success' : 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      })
      .catch((err) => {
        console.log({ err });
        toast({
          title: err.message,
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      });
  };

  const shortenToFirstTwoWords = (inputString) => {
    const words = inputString.split(' ');
    return words.slice(0, 2).join(' ');
  };

  return (
    <Box w="100%" display="flex" justifyContent="center" alignItems="center">
      <Box className="gordita400" w="100%">
        <ImageTextComponent
          imageSrc={appMockup}
          title={'Your next plan is now on the app!'}
          subtitle={''}
          description={
            'The simplest and secure way to get your tickets and access the events.'
          }
          widthImage="48.75rem"
          heightImage="48.5625rem"
          displayReverse={true}
          gapComponent="0px"
          button={
            isTablet ? (
              <Button
                fontWeight="700"
                textAlign="center"
                margin={isMobile ? '0 20px' : '20px 0'}
                fontSize="1rem"
                borderRadius="40px"
                outline="none"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                className="gordita700"
                css={{
                  width: '100%',
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                  cursor: 'pointer',
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                _disabled={{
                  bg: theme.buttons.primary.disabled,
                  color: theme.buttons.primary.colorDisabled,
                }}
                onClick={handlePortalysDownloadClick}
              >
                Download Portalys App
              </Button>
            ) : (
              <HStack marginTop={'60px'} justifyContent="center" gap="20px">
                <Box
                  onClick={handleAppStoreClick}
                  cursor="pointer"
                >
                  <Image
                    src={AppStore}
                    style={{
                      width: '120px',
                      height: '40px',
                    }}
                  />
                </Box>
                <Box
                  onClick={handleGooglePlayClick}
                  cursor="pointer"
                >
                  <Image
                    src={GooglePlay}
                    style={{
                      width: '135px',
                      height: '40px',
                    }}
                  />
                </Box>
              </HStack>
            )
          }
        />
        <Box
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          rowGap="1.25rem"
          columnGap="1.25rem"
          flexWrap="wrap"
          marginTop="30px"
        >
          {events?.map(
            (
              {
                coverPicture: image,
                eventName: heading,
                organizer,
                startDate: sdate,
                startTime: time,
                endTime: etime,
                track,
                categories,
                city,
                country,
                uuid,
                isLiked,
                eventId,
                organizedBy,
                slug,
                timezone,
              },
              index
            ) => {
              const trackObj = JSON.parse(track);
              const formattedDate = formatDate(sdate, timezone);
              const formattedTime = formatTime(time);
              const formattedETime = formatTime(etime);

              return (
                <Box
                  cursor="pointer"
                  onClick={() => navigate('/event/' + slug)}
                  key={index}
                  sx={theme.card.primary.shape}
                  pos={'relative'}
                >
                  <Box
                    width="2.75rem"
                    height="2.75rem"
                    flexShrink="0"
                    borderRadius="6.25rem"
                    border="1px solid rgba(255, 255, 255, 0.05)"
                    bg={'rgba(0, 0, 0, 0.50)'}
                    backdropFilter={'blur(150px)'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    pos={'absolute'}
                    right={'1.12rem'}
                    top={'1.12rem'}
                    _hover={{ background: !isLiked && '#000' }}
                  >
                    <Icon
                      as={isLiked ? AiFillHeart : AiOutlineHeart}
                      color="white.100"
                      w="1.5rem"
                      h="1.5rem"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeEvent(uuid);
                      }}
                    />
                  </Box>
                  <Image
                    w={'100%'}
                    h="28.0625rem"
                    objectFit={'cover'}
                    flexShrink={0}
                    borderRadius="var(--radius-radius-big, 0.9375rem)"
                    src={image}
                  />

                  <VStack
                    margin="1.25rem 0.62rem"
                    display="block"
                    gap={'0.62rem'}
                  >
                    <Heading
                      color="#FBFAFB"
                      className="gordita700"
                      fontSize="1.4375rem"
                      style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {heading}
                    </Heading>

                    <HStack
                      fontSize={{ base: 14, md: 16, '3xl': 16 }}
                      w="100%"
                      className="gordita400"
                    >
                      <Text className="gordita600">{formattedDate}</Text>
                      <Text> </Text>
                      <Text>{formattedTime + ' - ' + formattedETime}</Text>
                    </HStack>

                    <HStack
                      fontSize={{ base: 14, md: 16, '3xl': 16 }}
                      w="100%"
                      className="gordita400"
                    >
                      <Text
                        style={{
                          width: '50%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {organizedBy?.name}
                      </Text>
                      <Text
                        color="rgba(255, 255, 255, 0.60)"
                        fontSize="1rem"
                        style={{
                          width: '50%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {city || ''} {city && country ? ', ' : ''}{' '}
                        {country || ''}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              );
            }
          )}

          <MarketPlaceComponent
            maxW={
              !isTablet &&
              (events?.length % 3 === 0 ||
                (events?.length % 3 === 1 && events?.length !== 1))
                ? '48.75rem'
                : '23.75rem'
            }
            padding={
              !isTablet &&
              (events?.length % 3 === 0 ||
                (events?.length % 3 === 1 && events?.length !== 1))
                ? '2rem 5rem'
                : '2rem 1.25rem'
            }
            isTablet={isTablet}
            isMobile={isMobile}
          />
        </Box>
      </Box>
    </Box>
  );
}
